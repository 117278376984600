var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth auth--row register"},[_c('div',{staticClass:"register__content"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo"}})]),_c('ValidationObserver',{staticClass:"form",attrs:{"tag":"section"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',[_vm._v("Register")]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col"},[_c('BaseInput',{attrs:{"required":true,"placeholder":"Email","disabled":true,"element":_vm.user.email},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"First Name","required":true,"validateError":errors[0],"element":_vm.user.first_name},on:{"input":function($event){_vm.user.first_name = $event}},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Last Name","required":true,"validateError":errors[0],"element":_vm.user.last_name},on:{"input":function($event){_vm.user.last_name = $event}},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ verify_password: true },"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('PasswordInput',{attrs:{"required":true,"placeholder":"Set password","type":"password","name":"password","validateError":errors[0],"element":_vm.user.password},on:{"input":function($event){_vm.user.password = $event}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":"required|confirmed:password|min: 8|max: 255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('PasswordInput',{class:{ 'form-control--valid': valid },attrs:{"required":true,"placeholder":"Confirm password","type":"password","validateError":errors[0],"element":_vm.user.password_confirmation},on:{"input":function($event){_vm.user.password_confirmation = $event}},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('BaseButton',{staticClass:"button--full-width button--black button--uppercase",attrs:{"text":"Register","disabled":invalid,"loading":_vm.loader},on:{"click":_vm.register}})],1)]}}])}),_c('div',{staticClass:"auth__footer"},[_c('p',[_vm._v("© Collekt 2022")]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/terms-and-conditions"}},[_vm._v("Terms & Conditions")])],1),_c('li',[_c('router-link',{attrs:{"to":"/privacy-policy"}},[_vm._v("Privacy Policy")])],1)])])],1),_c('div',{staticClass:"register__image"})])}
var staticRenderFns = []

export { render, staticRenderFns }